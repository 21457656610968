import axios from "axios"
import _get from "lodash/get"
import _head from "lodash/head"
import _size from "lodash/size"
import _filter from "lodash/filter"
import _replace from "lodash/replace"
import { firebaseAuth } from "../../firebaseConfig"
import moment from "moment"

const API_PATHS = {
  CHECK_BOOKING_AVAILABILITY:
    "https://gofloaters.firebaseapp.com/spaces/checkavailabilities",
  SPACE_AVAILABILITY:
    "https://gofloaters.firebaseapp.com/spaces/getAvailability",
  // "https://gofloaters.firebaseapp.com//worqflexi/space/availability"
  CREATE_BOOKING:
    "https://gofloaters.firebaseapp.com/bookings/reactnative/doBooking",
  GET_METADATA: "https://gofloaters.firebaseapp.com/worqflexi/meta",
  CREATE_USER: `https://gofloaters.firebaseapp.com/updateuserdetails`,
  REFERRAL_VALIDATION: `https://gofloaters.firebaseapp.com/checkreferralcode`,
  MAKE_BOOKING: "https://gofloaters.firebaseapp.com/booking",
  CREATE_RAZORPAY_ORDER:
    "https://gofloaters.firebaseapp.com/booking/{{bookingId}}/razorpay-order",
  UPDATE_PAYMENT_STATUS:
    "https://gofloaters.firebaseapp.com/admin/updatestatus",
  CHECK_SPACE_AVAILABILITY:
    "https://gofloaters.firebaseapp.com/worqflexi/space/availability",
  CANCEL_BOOKING: "https://gofloaters.firebaseapp.com/booking/update ",
  REDEEM_BOOKING: "https://gofloaters.firebaseapp.com/booking/update ",
  ADD_FEEDBACK: "https://gofloaters.firebaseapp.com/addfeedback",
  APPLY_COUPON: "https://gofloaters.firebaseapp.com/user/voucher/discountcode",
}

export const getSeatsAvailable = async ({
  spaceId,
  dates,
  numberOfSeats,
  duration,
}) => {
  try {
    const response = await axios.post(API_PATHS.CHECK_BOOKING_AVAILABILITY, {
      spaceId,
      dates,
      numberOfSeats,
      duration,
    })
    return (
      _get(_head(_get(response, "data")), "seatsAvailable") -
      _get(_head(_get(response, "data")), "seatsBooked")
    )
  } catch (error) {
    return {}
  }
}

export const getSeatsAvailableAPI = async ({
  spaceId,
  dates,
  numberOfSeats,
  duration,
}) => {
  try {
    const response = await axios.get(
      `https://gofloaters.firebaseapp.com/spaces/getAvailability/?spaceId=${spaceId}&date=${dates}&numberOfSeats=${numberOfSeats}&duration=${duration}`
    )

    return _get(response, "data")
  } catch (error) {
    return error
  }
}

export const checkBookingAvailability = async ({
  spaceId,
  dates,
  numberOfSeats,
  duration,
}) => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(
      API_PATHS.CHECK_SPACE_AVAILABILITY,
      {
        spaceId,
        dates,
        numberOfSeats,
        duration,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log({
      data: _get(response, "data"),
      available:
        _size(
          _filter(_get(response, "data"), booking =>
            _get(booking, "availability")
          )
        ) === _size(dates),
    })
    return (
      _size(
        _filter(_get(response, "data"), booking =>
          _get(booking, "availability")
        )
      ) === _size(dates)
    )
  } catch (error) {
    return false
  }
}

export const createBooking = async bookingDetails => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(API_PATHS.MAKE_BOOKING, bookingDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return _get(response, "data")
  } catch (error) {
    return {}
  }
}

// export const createUser = async userDetails => {
//   const token = await auth.currentUser.getIdToken()
//   try {
//     const response = await axios.post(API_PATHS.CREATE_USER, userDetails, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     return response
//   } catch (error) {
//     return {}
//   }
// }

export const getMetaData = async () => {
  try {
    const response = await axios.get(API_PATHS.GET_METADATA, {
      headers: {
        "Content-Type": "application/json",
        apikey: "asdfkashfakfjhas8787faasdfasfdsdfgfdg",
      },
    })

    const metadata = _get(response, "data", {})
    return metadata
  } catch (e) {
    return {}
  }
}

export const createUser = async userDetails => {
  const token = await firebaseAuth.currentUser.getIdToken()
  console.log({ token })
  try {
    const response = await axios.post(API_PATHS.CREATE_USER, userDetails, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error) {
    return {}
  }
}

export const isValidReferralCode = async (referralCode, email) => {
  try {
    const response = await axios.post(
      API_PATHS.REFERRAL_VALIDATION,
      { referralCode, email },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    return response
  } catch (error) {
    return false
  }
}

export const createRazorpayOrder = async bookingId => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(
      _replace(API_PATHS.CREATE_RAZORPAY_ORDER, "{{bookingId}}", bookingId),
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return _get(response, "data.razorpayOrder")
  } catch (error) {
    console.log({ error })
    return {}
  }
}

export const updatePaymentStatus = async ({
  paymentRefNumber,
  spaceUsageFee,
  spaceType,
  uid,
  gstFee,
  redeemedPoints,
  id,
}) => {
  const token = await firebaseAuth.currentUser.getIdToken()
  console.log({ token })
  try {
    const response = await axios.post(
      API_PATHS.UPDATE_PAYMENT_STATUS,
      {
        id,
        status: "Prepaid",
        paymentRefNumber,
        bookingUsageFee: 0,
        spaceUsageFee,
        spaceType,
        gstFee,
        redeemedPoints,
        uid,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return _get(response, "data.booking")
  } catch (error) {
    return {}
  }
}

export const cancelBooking = async bookingId => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(
      API_PATHS.CANCEL_BOOKING,
      { id: bookingId, cancelledby: "User", status: "Cancelled" },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    console.log({ error })
    return {}
  }
}

export const redeemToConfrimBooking = async (booking, user) => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(
      API_PATHS.REDEEM_BOOKING,
      {
        id: _get(booking, "bookingkey"),
        status: "Prepaid",
        paymentRefNumber: "Redeemed",
        bookingUsageFee: 0,
        spaceUsageFee: _get(booking, "spaceUsageFee"),
        spaceType: _get(booking, "spaceType"),
        gstFee: 0,
        redeemedPoints: _get(booking, "spaceUsageFee"),
        uid: _get(user, "uid"),
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return _get(response, "data")
  } catch (error) {
    console.log({ error })
    return {}
  }
}

export const addUserBookingFeedback = async ({
  rating,
  commentForSpaces,
  customerName,
  spaceName,
  spaceId,
  bookingId,
}) => {
  const token = await firebaseAuth.currentUser.getIdToken()
  try {
    const response = await axios.post(
      API_PATHS.ADD_FEEDBACK,
      {
        rating,
        gofloaterrating: 5,
        commentForSpaces,
        customerName,
        spaceName,
        spaceId,
        feedbackDate: moment().valueOf(),
        bookingId,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return _get(response, "data.status") === "success"
  } catch (error) {
    return false
  }
}

export const applyDiscountCode = async ({ payload }) => {
  const token = await firebaseAuth.currentUser.getIdToken()

  try {
    const response = await axios.post(API_PATHS.APPLY_COUPON, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error) {
    return {}
  }
}
