import React from "react"
import moment from "moment"
import { v4 as uuid } from "uuid"
import _reduce from "lodash/reduce"
import _get from "lodash/get"
import _map from "lodash/map"
import _replace from "lodash/replace"
import _filter from "lodash/filter"
import _find from "lodash/find"
import _lowercase from "lodash/lowerCase"
import _last from "lodash/last"
import _reverse from "lodash/reverse"
import _size from "lodash/size"
import _head from "lodash/head"
import _includes from "lodash/includes"
import _sortBy from "lodash/sortBy"
import {
  BOOKING_STAGE_STATUS,
  BOOKING_TYPE_VALUES,
  SPACE_BOOKING_TYPES,
  STATUS,
} from "../constants/common"
import { isValidReferralCode } from "../api/common"
import { COLORS } from "../constants/colors"

export const calculateBookingAnounts = (
  user,
  space,
  bookings,
  bookingType,
  discount
) => {
  const booking = _head(bookings)
  const spaceUsageFee = calculateSpaceUsageFee(space, booking, bookingType)

  const userCreditsAvailable = Number(_get(user, "floatingpoints") || 0)
  let discountFee = 0
  if (Number(discount) > 0) {
    const discountAmount = (spaceUsageFee * Number(discount)) / 100
    discountFee = discountAmount.toFixed(2)
  } else {
    discountFee = 0
  }

  const spaceUsageFeeAfterDiscounts = spaceUsageFee - discountFee
  let netPayableAmount = 0
  if (userCreditsAvailable > spaceUsageFeeAfterDiscounts) {
    netPayableAmount = 0
  } else {
    netPayableAmount = spaceUsageFeeAfterDiscounts - userCreditsAvailable
    netPayableAmount = netPayableAmount.toFixed(2)
  }
  const gstFee = Number((netPayableAmount * 0.18).toFixed(2))
  const totalPayableAmount = (
    Number(netPayableAmount) + Number(gstFee)
  ).toFixed(2)

  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })

  breakup.push({
    label: "Discount",
    value: discountFee,
  })

  breakup.push({
    label: "Credits",
    value: userCreditsAvailable,
  })

  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })

  breakup.push({
    label: "GST (18%)",
    value: gstFee,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  // return {
  //   spaceUsageFee: spaceUsageFee,
  //   discountFee: discountFee,
  //   userCreditsAvailable: userCreditsAvailable,
  //   spaceUsageFeeAfterDiscounts: spaceUsageFeeAfterDiscounts,
  //   netPayableAmount: netPayableAmount,
  //   gstFee: gstFee,
  //   totalPayableAmount: totalPayableAmount,
  // }

  return {
    totalPrice: totalPayableAmount,
    breakup,
    spaceUsageFee,
    gst: gstFee,
  }
}

function calculateSpaceUsageFee(space, booking, bookingType) {
  let spaceUsageFee = 0
  const seatCount = _get(booking, "noOfSeats") || _get(booking, "headCount")
  if (space.spaceType == "Shared Workspace") {
    spaceUsageFee = Number(seatCount * space.priceperday)
  } else {
    if (
      booking.bookingType.toLowerCase() == "day" ||
      bookingType === SPACE_BOOKING_TYPES.DAY
    ) {
      spaceUsageFee = Number(space.priceperday)
    } else {
      spaceUsageFee = Number(space.priceperhr * booking.duration)
    }
  }
  return spaceUsageFee
}

export const getDeskBookingCharges = (space, bookings, user) => {
  const totalBookingPrice = _reduce(
    bookings,
    (totalCharge, currentBooking) => {
      let total = totalCharge
      total +=
        Number(_get(space, "priceperday")) *
        Number(_get(currentBooking, "noOfSeats"))
      return total
    },
    0
  )

  const spaceUsageFee = totalBookingPrice
  const discount = 0
  const credits = _get(user, "floatingpoints") || 0
  const netPayableAmount = totalBookingPrice - discount - credits
  const gst = (netPayableAmount * 0.18).toFixed(2)
  const totalPayableAmount = netPayableAmount + Number(gst)
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gst,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalPrice: totalPayableAmount,
    breakup: _filter(breakup, ({ value }) => value !== 0),
    spaceUsageFee,
    gst,
  }
}

export const getDeskBookingChargesFromBooking = (bookingInfo, user) => {
  const totalBookingPrice = _reduce(
    [bookingInfo],
    (totalCharge, currentBooking) => {
      let total = totalCharge
      total +=
        Number(_get(currentBooking, "priceperday")) *
        Number(_get(currentBooking, "headCount"))
      return total
    },
    0
  )

  const spaceUsageFee = totalBookingPrice
  const discount = 0
  const credits = _get(user, "floatingpoints") || 0
  const netPayableAmount = totalBookingPrice - discount - credits
  const gst = (netPayableAmount * 0.18).toFixed(2)
  const totalPayableAmount = netPayableAmount + Number(gst)
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gst,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalPrice: totalPayableAmount,
    breakup: _filter(breakup, ({ value }) => value !== 0),
    spaceUsageFee,
    gst,
  }
}

export const getNewBookingPlaceholder = (
  bookingDate,
  startTime,
  spaceDetails
) => ({
  id: uuid(),
  bookingDate: bookingDate + 86400000,
  startTime,
  noOfSeats: getMinSeats(spaceDetails),
})

export const getInitialDayBooking = (timeStamp, spaceDetails) => ({
  id: uuid(),
  bookingDate: timeStamp || getOperationalBookingDateAndTime(spaceDetails).date,
  startTime: timeStamp || getOperationalBookingDateAndTime(spaceDetails).time,
  noOfSeats: getMinSeats(spaceDetails),
})

export const getOperationalBookingDateAndTime = spaceDetails => {
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]
  const currentTime = moment()
  let selectedDay = weekdays[currentTime.isoWeekday() - 1]
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const currentTimePlusHour = currentTime.add(1, "hours")
    console.log({ issameday: moment().isSame(currentTimePlusHour, "day") })
    if (moment().isSame(currentTimePlusHour, "day")) {
      if (
        currentTimePlusHour.format("HH:mm") <
        _get(spaceDetails, `operationTiming.days.${selectedDay}.from`)
      ) {
        return {
          date: moment(
            _get(spaceDetails, `operationTiming.days.${selectedDay}.from`),
            "HH:mm"
          ).valueOf(),
          time: moment(
            _get(spaceDetails, `operationTiming.days.${selectedDay}.from`),
            "HH:mm"
          ).valueOf(),
        }
      }
      if (
        currentTimePlusHour.format("HH:mm") >
          _get(spaceDetails, `operationTiming.days.${selectedDay}.from`) &&
        currentTimePlusHour.format("HH:mm") <
          _get(spaceDetails, `operationTiming.days.${selectedDay}.to`)
      ) {
        return {
          date: currentTimePlusHour.valueOf(),
          time: currentTimePlusHour.valueOf(),
        }
      }
    }
  }
  // tomorrow
  // const tomorrow = moment().tz("Asia/Kolkata").add(1, "days").startOf("day")
  const tomorrow = moment().add(1, "days").startOf("day")
  selectedDay = weekdays[tomorrow.isoWeekday() - 1]
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment(
      `${tomorrow.format("YYYY-MM-DD")} ${_get(
        spaceDetails,
        `operationTiming.days.${selectedDay}.from`
      )}`
    )
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    }
  }
  // day after
  const dayAftertomorrow = moment().add(2, "days").startOf("day")
  selectedDay = weekdays[dayAftertomorrow.isoWeekday() - 1]
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment(
      `${dayAftertomorrow.format("YYYY-MM-DD")} ${_get(
        spaceDetails,
        `operationTiming.days.${selectedDay}.from`
      )}`
    )
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    }
  }

  const dayAfter3days = moment().add(3, "days").startOf("day")
  selectedDay = weekdays[dayAfter3days.isoWeekday() - 1]
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment(
      `${dayAfter3days.format("YYYY-MM-DD")} ${_get(
        spaceDetails,
        `operationTiming.days.${selectedDay}.from`
      )}`
    )
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    }
  }

  return {
    date: moment().valueOf(),
    time: moment().valueOf(),
  }
}

export const getMinSeats = space => Number(_get(space, "minHeadCount", 0))

export const isOfficeSpace = space =>
  _get(space, "spaceType") === "Shared Workspace" ||
  _includes(_get(space, "spaceSubType"), "Office Space")

export const getSpaceAvailabilityPayload = (spaceId, bookingPayload) => ({
  spaceId,
  duration: 1,
  numberOfSeats: [_get(bookingPayload, "headCount")],
  dates: [_get(bookingPayload, "startTime")],
})

export const addEventDetailsToThePayload = (payload, eventId) => {
  if (eventId) {
    return {
      ...payload,
      eventId,
    }
  }
  return payload
}

export const isBookingValid = (spaceDetails, booking, bookingType) => {
  console.log({ booking })
  const bookingDate = _get(booking, "bookingDate")
  const bookingDay = moment(bookingDate).format("dddd")
  const date = moment(bookingDate).format("D MMM YYYY")
  if (_get(spaceDetails, "seatsAvailable") === 0)
    return { status: STATUS.FAILED, msg: "No seats are available" }
  if (isOfficeSpace(spaceDetails) && _get(booking, "seatsAvailable") === 0)
    return { status: STATUS.FAILED, msg: `No seats available on ${date}` }
  if (
    _get(spaceDetails, "operationTiming.days") &&
    _get(booking, "bookingDate")
  ) {
    const bookingEndTime =
      new Date(_get(booking, "startTime")).getTime() +
      1000 * 60 * 60 * parseInt(_get(booking, "duration"), 10)

    const operationTimingsForDay = _get(
      spaceDetails,
      `operationTiming.days.${_lowercase(bookingDay)}`
    )
    if (
      bookingType === "Day" &&
      !isOfficeSpace(spaceDetails) &&
      moment().diff(bookingDate, "days") > 0
    ) {
      return { status: STATUS.SUCCESS, msg: "" }
    }
    if (!operationTimingsForDay || _get(operationTimingsForDay, "holiday")) {
      return {
        status: STATUS.FAILED,
        msg: `Space is not operational on your selected date. ${bookingDay} is holiday`,
      }
    }

    if (
      moment().isSame(bookingDate, "day") &&
      moment(booking?.startTime).isBefore(moment())
    ) {
      return {
        status: STATUS.FAILED,
        msg: `Space Can only be booked for future time.`,
      }
    }

    // const time = moment(startTime).format('LT');
    // const bookingStartTime = moment(`${date}, ${time}`, 'D MMM, LT').valueOf();
    // const startTimeIn24HrFormat =
    console.log({
      startTime: moment(_get(booking, "startTime")).format("HH:mm"),
      to: _get(operationTimingsForDay, "to"),
      from: _get(operationTimingsForDay, "from"),
      spacestartingtime: moment(
        `${date}, ${_get(operationTimingsForDay, "from")}`,
        "D MMM HH:mm"
      ).valueOf(),
      bookingStartTime: moment(
        `${date}, ${moment(_get(booking, "startTime")).format("HH:mm")}`,
        "D MMM YYYY HH:mm"
      ).valueOf(),
      spaceendTime: moment(
        `${date}, ${_get(operationTimingsForDay, "to")}`,
        "D MMM YYYY HH:mm"
      ).valueOf(),
    })
    console.log(
      moment(
        `${date}, ${moment(_get(booking, "startTime")).format("HH:mm")}`,
        "D MMM YYYY HH:mm"
      ).valueOf(),
      moment(
        `${date}, ${_get(operationTimingsForDay, "from")}`,
        "D MMM YYYY HH:mm"
      ).valueOf()
    )

    if (
      moment(
        `${date} ${moment(_get(booking, "startTime")).format("HH:mm")}`,
        "D MMM YYYY HH:mm",
        true // strict parsing
      ).isBefore(
        moment(
          `${date} ${_get(operationTimingsForDay, "from")}`,
          "D MMM YYYY HH:mm",
          true // strict parsing
        )
      )
    ) {
      return {
        status: STATUS.FAILED,
        msg: `For ${date}, Spaces is not operational at your selected time. Space opens at ${moment(
          _get(operationTimingsForDay, "from"),
          "HH:mm"
        ).format("hh:mm A")} only`,
      }
    }
    console.log({
      startTime: moment(_get(booking, "startTime")).format("HH:mm"),
      to: _get(operationTimingsForDay, "to"),
    })
    if (
      moment(
        `${date} ${moment(_get(booking, "startTime")).format("HH:mm")}`,
        "D MMM YYYY HH:mm",
        true // strict parsing
      ).valueOf() >
      moment(
        `${date} ${_get(operationTimingsForDay, "to")}`,
        "D MMM YYYY HH:mm",
        true // strict parsing
      ).valueOf()
    ) {
      return {
        status: STATUS.FAILED,
        msg: `For ${date}, Space is not operational at your selected time. Space closes at ${moment(
          _get(operationTimingsForDay, "to"),
          "HH:mm"
        ).format("hh:mm A")} only`,
      }
    }

    const currentTime = moment()
    if (
      moment().diff(bookingDate, "days") === 0 &&
      moment
        .duration(moment(_get(booking, "startTime")).diff(currentTime))
        .asMinutes() < 61
    ) {
      return {
        status: STATUS.FAILED,
        msg: `Please make your booking 61 minutes before you want to use the space. `,
      }
    }

    let minDuration = 0
    if (_get(spaceDetails, "minDuration")) {
      minDuration = _get(spaceDetails, "minDuration")
    } else {
      minDuration = 1
    }
    if (
      parseInt(_get(booking, "duration"), 10) < minDuration &&
      bookingType === "Hour"
    ) {
      // return this.presentAlert(`Minimum duration should be${  minDuration  }hour`)
      return {
        status: STATUS.FAILED,
        msg: `Minimum duration should be ${minDuration} hour`,
      }
    }
    if (
      parseInt(_get(booking, "noOfSeats"), 10) <
      parseInt(_get(spaceDetails, "minHeadCount"), 10)
    ) {
      return {
        status: STATUS.FAILED,
        msg: `Minimum headcount should be ${_get(
          spaceDetails,
          "minHeadCount"
        )}`,
      }
    }
    return {
      status: STATUS.SUCCESS,
    }
  }
  return {
    status: STATUS.FAILED,
    msg: "",
  }
}

export const areAllBookingsValid = (spaceDetails, bookings, bookingType) => {
  for (let i = 0; i < bookings.length; i += 1) {
    const bookingValid = isBookingValid(spaceDetails, bookings[i], bookingType)
    if (bookingValid.status === STATUS.FAILED) {
      return bookingValid
    }
  }
  return {
    status: STATUS.SUCCESS,
  }
}

export const getDeskBookingPayload = (space, user, dayBookings) => ({
  custId: _get(user, "uid"),
  bookingApp: "Website",
  spaceId: _get(space, "spaceId"),
  bookingType: "Day",
  bookingdate: moment().valueOf(),
  startTime: _head(
    _map(dayBookings, ({ bookingDate, startTime }) => {
      const date = moment(bookingDate).format("D MMM YYYY")
      const time = moment(startTime).format("LT")
      const bookingStartTime = moment(
        `${date}, ${time}`,
        "D MMM YYYY, LT"
      ).valueOf()
      return bookingStartTime
    })
  ),
  status: "Requested",
  headCount: _head(_map(dayBookings, ({ noOfSeats }) => noOfSeats)),
  redeemedPoints: 0,
  paymentRefNumber: "",
  bookingReason: "",
  durationDay: 1,
})

export const getInitialMeetingBooking = (timeStamp, spaceDetails, type) => {
  let bookingType = SPACE_BOOKING_TYPES.HOUR
  if (_get(spaceDetails, "dayPassAvailable"))
    bookingType = SPACE_BOOKING_TYPES.DAY
  if (type) bookingType = type

  let startTime =
    timeStamp || getOperationalBookingDateAndTime(spaceDetails).time
  if (
    isInstantlyBookableAwfis(spaceDetails) ||
    isInstantlyBookableInnov8(spaceDetails)
  )
    startTime = roundToNearestXXMinutes(startTime, 60)

  return {
    bookingDate:
      timeStamp || getOperationalBookingDateAndTime(spaceDetails).date,
    startTime,
    duration: _get(spaceDetails, "isInstantlyBookable") ? 0 : 1,
    noOfSeats: getMinSeats(spaceDetails),
    bookingType,
  }
}

export const getMeetingRoomBookingPayload = (
  space,
  user,
  meetingBookings,
  bookingType
) => {
  let payload = {
    custId: _get(user, "uid"),
    bookingApp: "Website",
    bookingdate: moment().valueOf(),
    spaceId: _get(space, "spaceId"),
    bookingReason: "",
    status: "Requested",
    headCount: _get(space, "seatsAvailable"),
    paymentRefNumber: "",
  }

  // "custId": "BIbX8qEnN2YFqdv3ROg0VinEaxh1",
  // "bookingApp":"Website",
  // "spaceId": "-Myp_fNWdQ86Q0DkdW1n",
  // "bookingType": "Day",
  // "bookingdate": 1705465800000,
  // "startTime": 1705465800000,
  // "endTime": 1705516200000,
  // "status": "Requested",
  // "headCount": 6,
  // "redeemedPoints": 0,
  // "paymentRefNumber": "",
  // "bookingReason": "",
  // "duration": 3

  // {
  //   "custId": "BIbX8qEnN2YFqdv3ROg0VinEaxh1",
  //   "bookingApp":"Website",
  //   "spaceId": "-Myp_fNWdQ86Q0DkdW1n",
  //   "bookingType": "Day",
  //   "bookingdate": 1705465800000,
  //   "startTime": 1705465800000,
  //   "endTime": 1705516200000,
  //   "status": "Requested",
  //   "headCount": 6,
  //   "redeemedPoints": 0,
  //   "paymentRefNumber": "",
  //   "bookingReason": "",
  //   "duration": 3
  // }

  if (bookingType === SPACE_BOOKING_TYPES.HOUR) {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.HOUR,
      startTime: _head(
        _map(meetingBookings, ({ bookingDate, startTime }) => {
          const date = moment(bookingDate).format("D MMM YYYY")
          const time = moment(startTime).format("LT")
          return moment(`${date}, ${time}`, "D MMM YYYY, LT").valueOf()
        })
      ),
      duration: _head(_map(meetingBookings, ({ duration }) => duration)),
    }
  } else {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.DAY,
      startTime: _head(
        _map(meetingBookings, ({ bookingDate, startTime }) => {
          const date = moment(bookingDate).format("D MMM YYYY")
          const time = moment(startTime).format("LT")
          return moment(`${date}, ${time}`, "D MMM YYYY, LT").valueOf()
        })
      ),
      durationDay: 1,
    }
  }

  return payload
}

export const isInstantlyBookableAwfis = space =>
  _get(space, "isInstantlyBookableAwfis")

export const isInstantlyBookableWeWork = space =>
  _get(space, "isInstantlyBookableWeWork")

export const isInstantlyBookableInnov8 = space =>
  _get(space, "isInstantlyBookableInnov8")

export const roundToNearestXXMinutes = (start, roundTo) => {
  const selectedTime = moment(start)
  let remainder =
    roundTo - ((selectedTime.minute() + selectedTime.second() / 60) % roundTo)

  remainder =
    remainder > roundTo / 2 ? (remainder = -roundTo + remainder) : remainder
  const changedDate = selectedTime
    .add(remainder, "minutes")
    .seconds(0)
    .valueOf()
  return moment(changedDate).valueOf()
}

export const getNewMeetingBookingPlaceholder = (
  meetingBookings,
  spaceDetails
) => {
  const booking = _last(meetingBookings)
  return {
    bookingDate: _get(booking, "bookingDate") + 86400000,
    startTime: _get(booking, "startTime"),
    duration: _get(booking, "duration"),
    noOfSeats: getMinSeats(spaceDetails),
  }
}

export const getMeetingRoomCharges = (space, bookings, bookingType, user) => {
  const totalBookingPrice = _reduce(
    bookings,
    (totalCharge, currentBooking) => {
      let total = totalCharge
      if (bookingType === SPACE_BOOKING_TYPES.DAY) {
        total += Number(_get(space, "priceperday"))
      } else {
        total +=
          Number(_get(space, "priceperhr")) *
          Number(_get(currentBooking, "duration"))
      }
      return total
    },
    0
  )

  console.log({ user })

  const spaceUsageFee = totalBookingPrice
  const discount = 0
  const credits = _get(user, "floatingpoints") || 0
  const netPayableAmount = totalBookingPrice - discount - credits
  const gst = Math.round(netPayableAmount * 0.18, 2)
  const totalPayableAmount = netPayableAmount + gst
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gst,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalPrice: totalPayableAmount,
    breakup: _filter(breakup, ({ value }) => value !== 0),
    spaceUsageFee,
    gst,
  }
}

export const getMeetingRoomChargesFromBooking = (bookings, user) => {
  const totalBookingPrice = _reduce(
    [bookings],
    (totalCharge, currentBooking) => {
      let total = totalCharge
      const bookingType = _get(currentBooking, "bookingType")
      if (bookingType === SPACE_BOOKING_TYPES.DAY) {
        total += Number(_get(currentBooking, "priceperday"))
      } else {
        total +=
          Number(_get(currentBooking, "priceperhr")) *
          Number(_get(currentBooking, "duration"))
      }
      return total
    },
    0
  )

  const spaceUsageFee = totalBookingPrice
  const discount = 0
  const credits = _get(user, "floatingpoints") || 0
  const netPayableAmount = totalBookingPrice - discount - credits
  const gst = Math.round(netPayableAmount * 0.18, 2)
  const totalPayableAmount = netPayableAmount + gst
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gst,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalPrice: totalPayableAmount,
    breakup: _filter(breakup, ({ value }) => value !== 0),
    spaceUsageFee,
    gst,
  }
}

export const getSpaceAvailabilityPayloadForMeetingSpaces = (
  spaceId,
  bookingPayload
) => {
  console.log({ bookingPayload })
  const payload = {
    spaceId,
    numberOfSeats: _get(bookingPayload, "headCount"),
    dates: [_get(bookingPayload, "startTime")],
  }
  if (_get(bookingPayload, "durationDay")) {
    payload.duration = _map(_get(bookingPayload, "startTimes"), () => 8)
  } else {
    payload.duration = _get(bookingPayload, "duration")
  }
  return payload
}

export const validateUpdateProfileDetails = ({
  displayName,
  phoneNumber,
  email,
  companyName,
  profession,
}) => {
  if (_size(displayName) < 3)
    return {
      status: STATUS.FAILED,
      msg: "Display name should be greater than 3 charaters",
    }
  if (_size(phoneNumber) !== 10)
    return { status: STATUS.FAILED, msg: "Enter valid phone number" }
  if (_size(email) === 0)
    return { status: STATUS.FAILED, msg: "Enter valid email" }
  if (_size(companyName) < 3)
    return { status: STATUS.FAILED, msg: "Enter valid company name" }
  if (_size(profession) === 0)
    return { status: STATUS.FAILED, msg: "Please select your profession" }
  return { status: STATUS.SUCCESS }
}

export const validateSignupDetails = async ({
  displayName,
  phoneNumber,
  email,
  companyName,
  profession,
  userCity,
  // referral,
}) => {
  const validProfileResponse = validateUpdateProfileDetails({
    displayName,
    phoneNumber,
    email,
    companyName,
    profession,
  })
  if (validProfileResponse.status === STATUS.SUCCESS) {
    if (_size(userCity) === 0)
      return { status: STATUS.FAILED, msg: "Please select your preferred city" }
  }

  return validProfileResponse
}

export const checkReferralCodeValidity = async (referral, email) => {
  try {
    if (_size(referral) > 0) {
      const response = await isValidReferralCode(referral, email)
      const { organizationId } = _get(response, "data") || {}
      if (!organizationId)
        return { status: STATUS.FAILED, msg: "Please enter valid referral" }
      return { status: STATUS.SUCCESS, organizationId }
    }
  } catch {
    return { status: STATUS.FAILED, msg: "Please enter valid referral" }
  }
  return { status: STATUS.FAILED, msg: "Please enter valid referral" }
}

// x = {
//   uid: "QIem01EMaSSeJ7rLpsSFv7yP4zF3",
//   displayName: "GoFloaters Test",
//   updatetype: "signup",
//   signupDate: 1686806944520,
//   signupSource: "PWA",
//   signupProvider: "Email",
//   reason: "Shared Workspace",
//   profession: "Startup employee",
//   email: "gofloaterstest@gofloaters.com",
//   phoneNumber: "9087335533",
//   signupReferralCode: "",
//   companyName: "GoFloaters",
//   location: {
//     city: "Bengaluru",
//     coords: { lat: 12.9715987, long: 77.5945627 },
//   },
// }

export const getCreateUserPayload = (user, userFilledDetails, metadata) => ({
  uid: _get(user, "uid"),
  displayName: _get(userFilledDetails, "displayName"),
  updatetype: "signup",
  signupDate: moment().valueOf(),
  signupSource: "Website",
  signupProvider: "Email",
  reason: _get(userFilledDetails, "reason"),
  profession: _get(userFilledDetails, "profession"),
  email: _get(userFilledDetails, "email"),
  phoneNumber: _get(userFilledDetails, "phoneNumber"),
  signupReferralCode: _get(userFilledDetails, "signupReferralCode"),
  organizationId: _get(userFilledDetails, "organizationId"),
  companyName: _get(userFilledDetails, "companyName"),
  location: getCityCoords(_get(userFilledDetails, "userCity"), metadata),
  campaignDetails: _get(userFilledDetails, "campaignDetails"),
  isVerified: true,
})

export const getOperationalCities = metadata => {
  const operationalCities = _get(metadata, "operationalCities.India")
  return _filter(operationalCities, city => _get(city, "availableForCustomer"))
}

export const getCityCoords = (city, metadata) => {
  const operationalCities = getOperationalCities(metadata)
  const selectedCity = _find(
    operationalCities,
    operationalCity => _get(operationalCity, "value") === city
  )
  return {
    city: city,
    coords: _get(selectedCity, "coords"),
  }
}

export const getSpacePriceDetails = spaceDetails => {
  if (isOfficeSpace(spaceDetails)) {
    return (
      <p>
        {_get(spaceDetails, "priceperdayPreDiscount") ? (
          <b className="priceStrike">
            ₹ {_get(spaceDetails, "priceperdayPreDiscount")}{" "}
          </b>
        ) : (
          ""
        )}
        ₹ <span className="bold">{_get(spaceDetails, "priceperday")}</span>
        /day
      </p>
    )
  }
  return (
    <p>
      {_get(spaceDetails, "priceperday") ? (
        <>
          {_get(spaceDetails, "priceperdayPreDiscount") ? (
            <b className="priceStrike">
              ₹ {_get(spaceDetails, "priceperdayPreDiscount")}{" "}
            </b>
          ) : (
            ""
          )}
          ₹ <span className="bold">{_get(spaceDetails, "priceperday")}</span>
          <span>/day or </span>
        </>
      ) : null}
      ₹ <span className="bold">{_get(spaceDetails, "priceperhr")}</span>/hour
    </p>
  )
}

export const getSpaceDetails = address => {
  const locality = _get(address, "locality", "")
  const city = _get(address, "city", "")
  return `${locality}, ${city}`
}

export const getFormattedStartDateTime = (startTime, format) =>
  moment(startTime).format(format)

export const getSpaceBookingDuration = booking => {
  if (_get(booking, "durationDay")) {
    return 8
  }
  if (_get(booking, "duration")) {
    return _get(booking, "duration")
  }
  return 1
}

export const getInformedStatusFill = status => {
  if (
    _includes(
      ["requested", "initiated", "approved", "confirmed", "prepaid", "closed"],
      _lowercase(status)
    )
  )
    return BOOKING_STAGE_STATUS.COMPLETED
  return null
}

export const getPreparingStatusFill = status => {
  if (_includes(["requested", "initiated", "approved"], _lowercase(status)))
    return BOOKING_STAGE_STATUS.IN_PROGRESS
  if (_includes(["confirmed", "prepaid", "closed"], _lowercase(status)))
    return BOOKING_STAGE_STATUS.COMPLETED
  return null
}

export const getConfirmedStatusFill = status => {
  if (_includes(["confirmed"], _lowercase(status)))
    return BOOKING_STAGE_STATUS.IN_PROGRESS
  if (_includes(["prepaid", "closed"], _lowercase(status)))
    return BOOKING_STAGE_STATUS.COMPLETED
  return null
}

export const getBookingStages = status => [
  {
    title: "Informed Staff",
    status: getInformedStatusFill(status),
  },
  {
    title: "Getting Space Ready",
    status: getPreparingStatusFill(status),
  },
  {
    title: "Confirmed",
    status: getConfirmedStatusFill(status),
  },
]
export const getSeparatorColor = (status, index) => {
  if (status === BOOKING_STAGE_STATUS.IN_PROGRESS && index === 0)
    return COLORS.STATUS_COLORS.SUCCESS
  if (
    status === BOOKING_STAGE_STATUS.COMPLETED &&
    (index === 0 || index === 1)
  ) {
    return COLORS.STATUS_COLORS.SUCCESS
  }
  return COLORS.PRIMARY_BORDER_COLOR
}

export const canBookingBeRated = booking =>
  _includes(["prepaid"], _lowercase(_get(booking, "status"))) &&
  !_get(booking, "rating")

export const isBookingConfirmed = booking =>
  _includes(["confirmed"], _lowercase(_get(booking, "status")))

export const isWeWorkBooking = booking =>
  _get(booking, "wework") && _get(booking, "wework.orderNumber")

export const isAwfisBooking = booking =>
  _get(booking, "awfis") && _get(booking, "awfis.orderNumber")

export const canBookingBeCancelled = booking =>
  _includes(["requested", "confirmed"], _lowercase(_get(booking, "status")))

export const isBookingActiveFuture = booking => {
  const startTime = _get(booking, "startTime")
  const currentTime = moment().valueOf()
  return (
    _includes(
      ["requested", "initiated", "approved", "confirmed", "prepaid", "closed"],
      _lowercase(_get(booking, "status"))
    ) && startTime > currentTime
  )
}

export const isAwfisDeskBooking = booking =>
  _get(booking, "awfis") && _get(booking, "spaceType") === "Shared Workspace"

export const isCancelBeforePrevDayMidnight = booking => {
  const dayStartOfBooking = moment(_get(booking, "startTime"))
    .startOf("day")
    .valueOf()
  const currentTime = moment().valueOf()
  return (
    _includes(
      ["requested", "initiated", "approved", "confirmed", "prepaid", "closed"],
      _lowercase(_get(booking, "status"))
    ) && dayStartOfBooking > currentTime
  )
}

export const getGoogleMapsLink = spacecoords =>
  `https://www.google.com/maps/search/?api=1&query=${spacecoords}`

export const getBookingConfirmationStatus = bookingDetails => {
  console.log({ bookingDetails })
  if (_includes(["requested"], _lowercase(_get(bookingDetails, "status")))) {
    return {
      statusLabel: "Booking Requested",
      showMessage: true,
    }
  }
  if (_includes(["prepaid"], _lowercase(_get(bookingDetails, "status")))) {
    return {
      statusLabel: "Booking Confirmed",
      showMessage: false,
    }
  }
  return {
    statusLabel: "",
    showMessage: false,
  }
}

export const getBookingsByType = (bookings, bookingType) => {
  const bookingsByType = _sortBy(
    _filter(bookings, booking => {
      const startTime = _get(booking, "startTime")
      let condition = false
      const dayStart = moment().startOf("day").valueOf()
      if (bookingType === BOOKING_TYPE_VALUES.ACTIVE) {
        condition =
          _includes(
            [
              "requested",
              "initiated",
              "approved",
              "confirmed",
              "prepaid",
              "closed",
            ],
            _lowercase(_get(booking, "status"))
          ) && startTime > dayStart
      } else {
        condition =
          _includes(["cancelled"], _lowercase(_get(booking, "status"))) ||
          startTime < dayStart
      }
      return condition
    }),
    booking => _get(booking, "bookingdate")
  )
  return bookingType === BOOKING_TYPE_VALUES.ACTIVE
    ? bookingsByType
    : _reverse(bookingsByType)
}

const removePunctuations = string =>
  _replace(string, /[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")

export const getCalndarInviteURLForGoogle = booking => {
  const title = `${_get(booking, "gofloatersSpaceName")} - ${_get(
    booking,
    "originalName"
  )}`
  const startDate = removePunctuations(
    moment(_get(booking, "startTime")).local(true).toISOString()
  )
  const endDate = removePunctuations(
    moment(
      _get(booking, "startTime") +
        getSpaceBookingDuration(booking) * 60 * 60 * 1000
    )
      .local(true)
      .toISOString()
  )
  const location = `${_get(booking, "spaceaddress.street")}, ${_get(
    booking,
    "spaceaddress.locality"
  )}, ${_get(booking, "spaceaddress.city")}, ${_get(
    booking,
    "spaceaddress.zipcode"
  )}`
  const notes = `${_get(booking, "gofloatersSpaceName")} - ${_get(
    booking,
    "originalName"
  )}`

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDate}%2F${endDate}&details=${notes}&location=${location}&text=${title}`
}

export const getCalndarInviteURLForOutlook = booking => {
  const title = `${_get(booking, "gofloatersSpaceName")} - ${_get(
    booking,
    "originalName"
  )}`
  const startDate = encodeURIComponent(
    moment(_get(booking, "startTime")).format("YYYY-MM-DDTHH:mm:SSZ")
  )
  const endDate = encodeURIComponent(
    moment(
      _get(booking, "startTime") +
        getSpaceBookingDuration(booking) * 60 * 60 * 1000
    ).format("YYYY-MM-DDTHH:mm:SSZ")
  )

  const location = `${_get(booking, "spaceaddress.street")}, ${_get(
    booking,
    "spaceaddress.locality"
  )}, ${_get(booking, "spaceaddress.city")}, ${_get(
    booking,
    "spaceaddress.zipcode"
  )}`
  const notes = `${_get(booking, "gofloatersSpaceName")} - ${_get(
    booking,
    "originalName"
  )}`
  // return `https://outlook.live.com/calendar/0/action/compose?allday=false&body=new%20description&enddt=2023-10-26T09%3A15%3A00%2B00%3A00&location=gofloatsers&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-25T08%3A45%3A00%2B00%3A00&subject=gofloaters`
  return `https://outlook.live.com/calendar/0/action/compose?allday=false&body=${notes}&enddt=${endDate}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDate}&subject=${title}`
}
